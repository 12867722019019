import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import VueAxios from "vue-axios";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(VueAxios, axios)
  .component("v-select", vSelect)
  .mount("#app");

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    beforeEnter: (to, from, next) => {
      if (!isLoggedIn()) {
        next("/login"); // นำผู้ใช้ไปยังหน้า login หากไม่ได้ login
      } else {
        next(); // ทำให้สามารถเข้าถึง route นี้ได้ถ้าผู้ใช้ได้ login
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
];
function isLoggedIn() {
  // เปลี่ยน 'jwt-token' นี้เป็น key ที่คุณใช้เก็บ token ใน local storage หรือ cookie
  const token = localStorage.getItem("token");

  // ตรวจสอบว่ามี token อยู่หรือไม่
  return token ? true : false;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <div class="home">
    <div class="row justify-content-center">
      <div class="col-8">
        <h1>ดู report lotto</h1>
        <div class="row justify-content-center">
          <div class="col-6">
            <label for="game">เลือก วันเริ่ม</label>
            <input
              ref="datepicker1"
              class="form-control"
              type="text"
              v-model="date_start"
            />
          </div>
          <div class="col-6">
            <label for="game">เลือก วันสิ้นสุด</label>
            <input
              ref="datepicker2"
              class="form-control"
              type="text"
              v-model="date_end"
            />
          </div>
        </div>
        <button
          class="btn btn-primary my-2"
          @click="getData()"
          :disabled="isLoading"
          v-if="!isLoading"
        >
          ยืนยัน
        </button>
        <button v-else class="btn btn-primary my-2" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
        <table class="table table-dark" v-if="result && !isLoading">
          <thead>
            <tr>
              <th colspan="3"></th>
              <th>
                <h6 class="text-success" v-if="result.all_total_amount > 0">
                  ยอดรวม

                  <span
                    class="badge bg-success"
                    data-bs-toggle="tooltip"
                    :title="`หวยไทย : ${formatCurrency(
                      parseFloat(result.all_total_amount_thai)
                    )} , หวยอื่นๆ : ${formatCurrency(
                      parseFloat(
                        result.all_total_amount - result.all_total_amount_thai
                      )
                    )}`"
                  >
                    {{
                      result.all_total_amount.toLocaleString("th-TH", {
                        style: "currency",
                        currency: "THB",
                      })
                    }}
                  </span>
                </h6>
              </th>
              <th>
                <h6 class="text-success" v-if="result.all_total_prize > 0">
                  ยอดรวม
                  <span
                    class="badge bg-success"
                    data-bs-toggle="tooltip"
                    :title="`หวยไทย : ${formatCurrency(
                      parseFloat(result.all_total_prize_thai)
                    )} , หวยอื่นๆ : ${formatCurrency(
                      parseFloat(
                        result.all_total_prize - result.all_total_prize_thai
                      )
                    )}`"
                    >{{
                      result.all_total_prize.toLocaleString("th-TH", {
                        style: "currency",
                        currency: "THB",
                      })
                    }}</span
                  >
                </h6>
              </th>
              <th>
                <h6 class="text-success" v-if="result.all_total > 0">
                  ยอดรวม
                  <span
                    class="badge bg-success"
                    data-bs-toggle="tooltip"
                    :title="`หวยไทย : ${formatCurrency(
                      parseFloat(result.all_total_thai)
                    )} , หวยอื่นๆ : ${formatCurrency(
                      parseFloat(result.all_total - result.all_total_thai)
                    )}`"
                    >{{
                      result.all_total.toLocaleString("th-TH", {
                        style: "currency",
                        currency: "THB",
                      })
                    }}</span
                  >
                </h6>
              </th>
              <th>
                <h6 class="text-success" v-if="result.all_agent > 0">
                  ยอดรวม
                  <span
                    class="badge bg-success"
                    data-bs-toggle="tooltip"
                    :title="`หวยไทย : ${formatCurrency(
                      parseFloat(result.all_agent_thai ** (2 / 100))
                    )} , หวยอื่นๆ : ${formatCurrency(
                      parseFloat(
                        (result.all_agent - result.all_agent_thai) * (2 / 100)
                      )
                    )}`"
                    >{{
                      (result.all_agent * (2 / 100)).toLocaleString("th-TH", {
                        style: "currency",
                        currency: "THB",
                      })
                    }}</span
                  >
                </h6>
              </th>
            </tr>
            <tr>
              <th scope="col">#</th>
              <th scope="col">SENIOR</th>
              <th scope="col">AGENT</th>
              <th scope="col">
                <span data-bs-toggle="tooltip" title="เทรินโอเวอร์(ยอดแทง)"
                  >TURNOVER</span
                >
              </th>
              <th scope="col">
                <span data-bs-toggle="tooltip" title="ยอดที่ลูกค้าได้"
                  >WINAMOUNT</span
                >
              </th>
              <th scope="col">TOTAL(100%)</th>
              <th scope="col">COMPANY (2%)</th>
              <!--   <th scope="col">USE SENIOR</th>
              <th scope="col">COMPANY</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in result.data" :key="i">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.senior_name ? item.senior_name : "ES" }}</td>
              <!-- namesenior -->
              <td>{{ item.name }}</td>
              <!-- agent -->
              <td>
                <span
                  data-bs-toggle="tooltip"
                  :title="`หวยไทย : ${formatCurrency(
                    parseFloat(item.total_amount_thai)
                  )} , หวยอื่นๆ : ${formatCurrency(
                    parseFloat(item.total_amount - item.total_amount_thai)
                  )}`"
                >
                  {{
                    item.total_amount.toLocaleString("th-TH", {
                      style: "currency",
                      currency: "THB",
                    })
                  }}
                </span>
              </td>
              <!-- total amount -->
              <td>
                <span
                  data-bs-toggle="tooltip"
                  :title="`หวยไทย : ${formatCurrency(
                    parseFloat(
                      item.total_prize_thai < 0
                        ? item.total_prize_thai * -1
                        : item.total_prize_thai
                    )
                  )} , หวยอื่นๆ : ${formatCurrency(
                    parseFloat(
                      (item.total_prize < 0
                        ? item.total_prize * -1
                        : item.total_prize) -
                        (item.total_prize_thai < 0
                          ? item.total_prize_thai * -1
                          : item.total_prize_thai)
                    )
                  )}`"
                >
                  {{
                    (item.total_prize < 0
                      ? item.total_prize * -1
                      : item.total_prize
                    ).toLocaleString("th-TH", {
                      style: "currency",
                      currency: "THB",
                    })
                  }}
                </span>
              </td>
              <!-- total prize -->
              <td>
                <span
                  data-bs-toggle="tooltip"
                  :title="`หวยไทย : ${formatCurrency(
                    parseFloat(item.total_thai)
                  )} , หวยอื่นๆ : ${formatCurrency(
                    parseFloat(item.total - item.total_thai)
                  )}`"
                >
                  {{
                    item.total.toLocaleString("th-TH", {
                      style: "currency",
                      currency: "THB",
                    })
                  }}</span
                >
              </td>
              <td class="">
                <span
                  data-bs-toggle="tooltip"
                  :title="`หวยไทย : ${formatCurrency(
                    parseFloat(item.agent_thai * (2 / 100))
                  )} , หวยอื่นๆ : ${formatCurrency(
                    parseFloat((item.agent - item.agent_thai) * (2 / 100))
                  )}`"
                >
                  {{
                    (item.agent * (2 / 100)).toLocaleString("th-TH", {
                      style: "currency",
                      currency: "THB",
                    })
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
let domain = "";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
export default {
  data() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return {
      showdata: null,
      bandgame: null,
      allagent: [],
      allbandgame: [],
      date_start: yesterday.toISOString().split("T")[0],
      date_end: new Date().toISOString().split("T")[0],
      result: null,
      total_less: 0,
      total_more: 0,
      isLoading: false, // สถานะการโหลด
    };
  },
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  methods: {
    getData() {
      if (this.date_start && this.date_end) {
        const start = new Date(this.date_start + " 00:00:00").getTime();
        const end = new Date(this.date_end + " 23:59:59").getTime();
        this.isLoading = true;
        this.axios
          .get(
            domain +
              `/api/get_es?&start=${start.toString().substring(0, 10)}&end=${end
                .toString()
                .substring(0, 10)}`
          )
          .then((result) => {
            if (result.data.code === 0) {
              this.result = result.data.payload;
              this.result.all_total_prize =
                result.data.payload.all_total_prize * -1;
              this.GetDataLotto_thai(this.date_start, this.date_end);
            } else {
              this.$swal({
                title: "error",
                icon: "error",
                text: result.code,
              });
            }
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
      } else {
        this.$swal({
          title: "error",
          icon: "error",
          text: "not data",
        });
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("th-TH", {
        style: "currency",
        currency: "THB",
      });
    },
    GetDataLotto_thai() {
      this.isLoading = true;
      const start = new Date(this.date_start + " 00:00:00").getTime();
      const end = new Date(this.date_end + " 23:59:59").getTime();
      this.axios
        .get(
          domain +
            `/api/get_es_thai?&start=${start
              .toString()
              .substring(0, 10)}&end=${end.toString().substring(0, 10)}`
        )
        .then((result) => {
          if (result.data.code === 0) {
            let data_all = {
              data: [],
            };
            let a = this.result.data;
            let b = result.data.payload.data;
            for (let i = 0; i < a.length; i++) {
              for (let j = 0; j < b.length; j++) {
                if (a[i].name === b[j].name) {
                  const mergedObject = { ...a[i], ...b[j] };
                  data_all.data.push(mergedObject);
                  break;
                }
              }
            }
            const newObj = {};
            newObj.all_total_thai = result.data.payload.all_total_thai;
            newObj.all_senior_thai = result.data.payload.all_senior_thai;
            newObj.all_agent_thai = result.data.payload.all_agent_thai;
            newObj.all_com_thai = result.data.payload.all_com_thai;
            newObj.all_total_amount_thai =
              result.data.payload.all_total_amount_thai;
            newObj.all_total_prize_thai =
              parseFloat(result.data.payload.all_total_prize_thai) * -1;
            newObj.all_total = this.result.all_total;
            newObj.all_senior = this.result.all_senior;
            newObj.all_agent = this.result.all_agent;
            newObj.all_com = this.result.all_com;
            newObj.all_total_amount = this.result.all_total_amount;
            newObj.all_total_prize = parseFloat(this.result.all_total_prize);

            // console.log("1", this.result);
            var isDuplicate = false;
            let result2 = Object.assign({}, data_all, newObj);
            this.result.data.forEach(function (newValue) {
              isDuplicate = false; // รีเซ็ตค่าตรวจสอบค่าซ้ำก่อนทุกครั้งในลูปใหม่
              result2.data.forEach(function (item) {
                if (item.name === newValue.name) {
                  isDuplicate = true;
                }
              });
              if (!isDuplicate) {
                result2.data.push(newValue);
              }
            });
            this.result = result2;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },
  async mounted() {
    flatpickr(this.$refs.datepicker1);
    flatpickr(this.$refs.datepicker2);
    if (process.env.NODE_ENV !== "development") {
      domain = "https://rp-lt-mt.sunnygreen.online";
      console.log(domain);
    } else {
      domain = "http://localhost:3555";
    }
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
    console.log(tooltipList);
  },
};
</script>
